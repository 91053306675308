import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import "./createBusiness.scss";
import companyLogo from "../../assets/images/berb-logo-black.svg";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import foodIcon from "../../assets/images/foodicon.png";
import Dropzone, { useDropzone } from "react-dropzone";
import demoupload from "../../assets/images/demoupload.png";
import uploadicon from "../../assets/images/uploadicon.svg";
import "../../styles/common.scss";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import foodicon from "../../assets/images/foodicon.png";
import testingImg from "../../assets/images/testingImg.png";
import { CheckBox } from "@material-ui/icons";
import { Checkbox, FormControl, Stack, TextField, Select, MenuItem, InputLabel, } from "@mui/material";
import { border } from "@mui/system";
import { getMethod, postMethod, putMethod, } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import { useLocation, useNavigate, useParams } from "react-router";
import { set } from "date-fns/esm";
import { LocalizationProvider, MobileTimePicker, MobileDatePicker, } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import imgBaseUrl from "../../utils/apis/locale/env.json";
import { parsePhoneNumber } from "react-phone-number-input";
import { useLoadScript } from "@react-google-maps/api";
import Geosuggest from "react-geosuggest";
import ToastService from "react-material-toast";
import flags from "react-phone-number-input/flags";
import { ListSubheader, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ShimmerThumbnail } from "react-shimmer-effects";
import DropdownList from "react-widgets/DropdownList";
import moment from "moment";
import "react-widgets/styles.css";
import debounce from "lodash.debounce";

const shimArr = [1, 2, 3, 4];
const libraries = ["places"];
const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

export default function CreateBusiness() {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const inputRef = React.useRef();
  const params = useParams();
  const navigate = useNavigate();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA2BMnK1rmXMXaELg7vqLn2DzrmQZY9_kY',
    libraries,
  });
  const [businessHours, setBusinessHours] = useState([
    {
      days: "sunday",
      startTime: "00:00:00",
      endTime: "00:00:00",
      is_holiday: true,
    },
    {
      days: "monday",
      startTime: "00:00:00",
      endTime: "00:00:00",
      is_holiday: true,
    },
    {
      days: "tuesday",
      startTime: "00:00:00",
      endTime: "00:00:00",
      is_holiday: true,
    },
    {
      days: "wednesday",
      startTime: "00:00:00",
      endTime: "00:00:00",
      is_holiday: true,
    },
    {
      days: "thursday",
      startTime: "00:00:00",
      endTime: "00:00:00",
      is_holiday: true,
    },
    {
      days: "friday",
      startTime: "00:00:00",
      endTime: "00:00:00",
      is_holiday: true,
    },
    {
      days: "saturday",
      startTime: "00:00:00",
      endTime: "00:00:00",
      is_holiday: true,
    },
  ]);
  const [value, setValue] = useState({
    address: "",
    country_id: "",
    state_id: "",
    city_id: "",
    businessName: "",
    latitude: "",
    longitude: "",
    opening_closing_holiday: [],
    countryCode: "",
    phoneContactNumber: "",
    // SMScontactNumber: "",
    website_link: "",
    instagram_link: "",
    youtube_link: "",
    facebook_link: "",
    // "averagePricePerPax": "",
    businessType: [],
    cuisine: [],
    atmosphere: [],
    facility: [],
    goodFor: [],
    parking: [],
    image_ids: [],
    menu_url: "",
  });

  const [businessMaster, setBusinessMaster] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [countryName, setCountryName] = useState();
  const [countryId, setCountryId] = useState();
  const [stateList, setStateList] = useState([]);
  const [stateName, setStateName] = useState();
  const [stateId, setStateId] = useState();
  const [cityList, setCityList] = useState([]);
  const [cityName, setCityName] = useState();
  const [getEditData, setGetEditData] = useState();
  const [mobileCountryCode, setMobileCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [smsMobileNumber, setSmsMobileNumber] = useState("");
  const [selectedBusinessType, setSelectedBusinessType] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedAtmosphere, setSelectedAtmosphere] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [selectedGoodFor, setSelectedGoodFor] = useState([]);
  const [selectedParking, setSelectedParking] = useState([]);
  const [selectedBusinessTypeSlug, setSelectedBusinessTypeSlug] = useState([]);
  const [selectedCuisineSlug, setSelectedCuisineSlug] = useState([]);
  const [selectedAtmosphereSlug, setSelectedAtmosphereSlug] = useState([]);
  const [selectedFacilitySlug, setSelectedFacilitySlug] = useState([]);
  const [selectedGoodForSlug, setSelectedGoodForSlug] = useState([]);
  const [selectedParkingSlug, setSelectedParkingSlug] = useState([]);
  const [view, setView] = useState();
  const [modalType, setModalType] = useState();
  const [businessImages, setBusinessImages] = useState([]);
  const [businessImagesData, setBusinessImagesData] = useState([]);
  const [businessImagesIds, setBusinessImagesIds] = useState([]);
  const [stateDataList, setStateDataList] = useState([]);
  const [cityDataList, setCityDataList] = useState([]);
  const [selectedCount, setSelectedCount] = useState("");
  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [selectedCuisineList, setSelectedCuisineList] = useState([]);
  const [selectedAtmosphereList, setSelectedAtmosphereList] = useState([]);
  const [selectedFacilitiesList, setSelectedFacilitiesList] = useState([]);
  const [selectedGoodForList, setSelectedGoodForList] = useState([]);
  const [selectedParkingList, setSelectedParkingList] = useState([]);
  const [createBusinessId, setCreateBusinessId] = useState(
    params.id ? params.id : ""
  );
  const [reservationLink, setReservationLink] = useState("");
  const [ribbonText, setRibbonText] = useState("");
  const [isRecommend, setIsRecommend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [reservation_name, setReservation_name] = useState("");
  const [commonModel, setCommonModel] = useState([]);
  const [error, setError] = useState(false);
  const [searchPlaceIdList, setSearchPlaceIdList] = useState([]);

  const [businessOpendate, setBusinessOpenDate] = React.useState("");

  useEffect(() => {
    getCountryListData();
    SearchBusinessList();
    getReservationType();
    if (params.id !== "add") {
      getBusinessData(params.id);
    } else {
      getBusinessMaster();
    }
  }, []);

  const getBusinessMaster = async () => {
    setLoading(true);
    const result = await getMethod(url.businessmaster);
    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }
    setBusinessMaster(result.data);
    setSelectedBusinessList(result.data.BusinessMaster);

    setSelectedCuisineList(result.data.CuisineTypes);
    setSelectedAtmosphereList(result.data.AtmosphereTypes);
    setSelectedFacilitiesList(result.data.ExtraFacilities);
    setSelectedGoodForList(result.data.PopularItems);
    setSelectedParkingList(result.data.ParkingTypes);
    setLoading(false);
  };

  const getCountryListData = async () => {
    const result = await getMethod(url.getCountryList);
    setCountryList(result.data);
  };

  const getStateListData = async (country_id) => {
    const result = await getMethod(
      url.getCountryList + "?country_id=" + country_id
    );
    setStateList(result?.data?.[0]?.data?.state);
    setStateDataList(result?.data?.[0]?.data?.state);
  };

  const getCityListData = async (country_id, state_id) => {
    const result = await getMethod(
      url.getCountryList + "?country_id=" + country_id + "&state_id=" + state_id
    );
    setCityList(result?.data?.[0]?.data?.cities);
    setCityDataList(result?.data?.[0]?.data?.cities);
  };

  function getStateCityListWithId(id) {
    const result = getMethod(url.getStateCityList + id);

    result.then(
      function (res) {
        return res.data; // "Promise resolved successfully"
      },
      (err) => {
        return false; // Error: "Promise rejected"
      }
    );
  }

  const getBusinessData = async () => {
    const busResult = await getMethod(url.businessmaster);
    setBusinessMaster(busResult.data);

    const result = await getMethod(url.getBusinessData + params.id);

    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }

    const resultDetails = result.data;

    let images = [...businessImages];
    let imageList = [...businessImagesData];
    let imageListIds = [...businessImagesIds];

    resultDetails.image_ids.forEach((value, index) => {
      images.push(value);
      imageList.push(value);
      imageListIds.push({ imageId: value.id, orderNumber: value.orderNumber });
    });

    setBusinessImages(images);
    setBusinessImagesData(imageList);
    setBusinessImagesIds(imageListIds);

    setValue((prevState) => ({
      ...prevState,
      image_ids: imageListIds,
    }));

    setReservationLink(result.data.reservation_link);
    setReservation_name(
      resultDetails.reservation_types.length > 0 &&
      resultDetails.reservation_types[0].name
    );
    setRibbonText(result.data.ribbon_text);
    setIsRecommend(result.data.isRecommended);

    if (resultDetails.country.id && resultDetails.country.id !== null) {
      getStateListData(resultDetails.country.id);
    }

    if (
      resultDetails.country.id &&
      resultDetails.state !== null &&
      resultDetails.state.id
    ) {
      getCityListData(resultDetails?.country.id, resultDetails?.state?.id);
    }


    let busDatas = [...selectedBusinessType];
    let busDatasSlug = [...selectedBusinessTypeSlug];
    resultDetails.businessType.forEach((value, index) => {
      busDatas.push(value.id);
      busDatasSlug.push(value.slug);
    });
    setSelectedBusinessType(busDatas);
    setSelectedBusinessTypeSlug(busDatasSlug);
    setValue((prevState) => ({
      ...prevState,
      businessType: busDatasSlug,
    }));

    if (busResult.data.BusinessMaster.length > 0) {
      const slist = [];
      const uslist = [];
      busResult.data.BusinessMaster.forEach((value, index) => {
        if (busDatas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedBusinessList(slist.concat(uslist));
    }
    let cusDatas = [...selectedCuisine];
    let cusDatasSlug = [...selectedCuisineSlug];
    resultDetails.cuisine.forEach((value, index) => {
      cusDatas.push(value.id);
      cusDatasSlug.push(value.slug);
    });
    setSelectedCuisine(cusDatas);
    setSelectedCuisineSlug(cusDatasSlug);
    setValue((prevState) => ({
      ...prevState,
      cuisine: cusDatasSlug,
    }));

    if (busResult.data.CuisineTypes.length > 0) {
      const slist = [];
      const uslist = [];
      busResult.data.CuisineTypes.forEach((value, index) => {
        if (cusDatas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedCuisineList(slist.concat(uslist));
    }

    let atmDatas = [...selectedAtmosphere];
    let atmDatasSlug = [...selectedAtmosphereSlug];
    resultDetails.atmosphere.forEach((value, index) => {
      atmDatas.push(value.id);
      atmDatasSlug.push(value.slug);
    });
    setSelectedAtmosphere(atmDatas);
    setSelectedAtmosphereSlug(atmDatasSlug);
    setValue((prevState) => ({
      ...prevState,
      atmosphere: atmDatasSlug,
    }));

    if (busResult.data.AtmosphereTypes.length > 0) {
      const slist = [];
      const uslist = [];
      busResult.data.AtmosphereTypes.forEach((value, index) => {
        if (atmDatas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedAtmosphereList(slist.concat(uslist));
    }

    let facDatas = [...selectedFacility];
    let facDatasSlug = [...selectedFacilitySlug];
    resultDetails.facility.forEach((value, index) => {
      facDatas.push(value.id);
      facDatasSlug.push(value.slug);
    });
    setSelectedFacility(facDatas);
    setSelectedFacilitySlug(facDatasSlug);
    setValue((prevState) => ({
      ...prevState,
      facility: facDatasSlug,
    }));

    if (busResult.data.ExtraFacilities.length > 0) {
      const slist = [];
      const uslist = [];
      busResult.data.ExtraFacilities.forEach((value, index) => {
        if (facDatas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedFacilitiesList(slist.concat(uslist));
    }

    let godDatas = [...selectedGoodFor];
    let godDatasSlug = [...selectedGoodForSlug];
    resultDetails.goodFor.forEach((value, index) => {
      godDatas.push(value.id);
      godDatasSlug.push(value.slug);
    });
    setSelectedGoodFor(godDatas);
    setSelectedGoodForSlug(godDatasSlug);
    setValue((prevState) => ({
      ...prevState,
      goodFor: godDatasSlug,
    }));

    if (busResult.data.PopularItems.length > 0) {
      const slist = [];
      const uslist = [];
      busResult.data.PopularItems.forEach((value, index) => {
        if (godDatas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedGoodForList(slist.concat(uslist));
    }

    let parDatas = [...selectedParking];
    let parDatasSlug = [...selectedParkingSlug];
    resultDetails.parking.forEach((value, index) => {
      parDatas.push(value.id);
      parDatasSlug.push(value.slug);
    });
    setSelectedParking(parDatas);
    setSelectedParkingSlug(parDatasSlug);
    setValue((prevState) => ({
      ...prevState,
      parking: parDatasSlug,
    }));

    if (busResult.data.ParkingTypes.length > 0) {
      const slist = [];
      const uslist = [];
      busResult.data.ParkingTypes.forEach((value, index) => {
        if (parDatas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedParkingList(slist.concat(uslist));
    }

    let busHoursList = [...businessHours];
    resultDetails.opening_closing_holiday.forEach((value, index) => {
      busHoursList.map((k, i) => {
        if (k.days === value.weekDay) {
          busHoursList[i]["startTime"] = value.startTime;
          busHoursList[i]["endTime"] = value.endTime;
          busHoursList[i]["is_holiday"] = value.is_holiday;
        }
      })
      // busHoursList[index]["startTime"] = value.startTime;
      // busHoursList[index]["endTime"] = value.endTime;
      // busHoursList[index]["is_holiday"] = value.is_holiday;
    });
    setBusinessHours(busHoursList);

    if (resultDetails.businessOpen_date) {
      setBusinessOpenDate(resultDetails.businessOpen_date);
    }

    setValue((prevState) => ({
      ...prevState,
      ["address"]: resultDetails.address,
      ["country_id"]:
        resultDetails.country !== null && resultDetails.country.id,
      ["state_id"]: resultDetails.state !== null && resultDetails.state.id,
      ["city_id"]: resultDetails.city !== null && resultDetails.city.id,
      ["businessName"]: resultDetails.businessName,
      ["latitude"]: resultDetails.latitude,
      ["longitude"]: resultDetails.longitude,
      ["description"]: resultDetails.description,
      ["opening_closing_holiday"]: [],
      ["countryCode"]: resultDetails.countryCode
        ? resultDetails.countryCode
        : "+1",
      ["phoneContactNumber"]: resultDetails.phoneContactNumber,
      ["SMScontactNumber"]: resultDetails.SMScontactNumber,
      ["website_link"]: resultDetails.website_link,
      ["instagram_link"]: resultDetails.instagram_link,
      ["youtube_link"]: resultDetails.youtube_link,
      ["facebook_link"]: resultDetails.facebook_link,
      ["averagePricePerPax"]: resultDetails.averagePricePerPax,
      ["menu_url"]: resultDetails.menu_url,
    }));
    if (resultDetails.state === null) {
      setError(true);
    }
    if (resultDetails.city === null) {
      setError(true);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////

  const handleBusinesChanged = (e) => {
    setValue((prevState) => ({
      ...prevState,
      ["businessName"]: e,
    }));
  };

  const handleAddressChanged = (e) => {
    setValue((prevState) => ({
      ...prevState,
      ["address"]: e,
    }));
  };

  const handleChangeBusinessOpenDate = (newValue) => {
    let startDate = moment(newValue).format("YYYY-MM-DD");
    setBusinessOpenDate(startDate);
  };

  const SearchBusinessList = async () => {
    const result = await getMethod(url.getAllBusinessList);
    setSearchPlaceIdList(result?.data?.length > 0 && result?.data);
  };

  const onUpdateSuggests = (suggests, activeSuggest) => {
    if (suggests) {
      suggests.map((k) =>
        searchPlaceIdList.length > 0 &&
        searchPlaceIdList.map((j) => {
          if (j.placeId === k.placeId) {
            suggests.splice(suggests.findIndex((obj) => obj.placeId === j.placeId), 1);
          }
        })
      );
    }
  };

  const onSuggestSelect = (suggest) => {
    //select business type whenever we fill the data
    if (suggest) {
      console.log(suggest);
      setValue((prevState) => ({
        ...prevState,
        placeId: suggest.placeId,
      }));
      let items = [];
      suggest.gmaps.types.map((e) => {
        return selectedBusinessList.map((event, index) => {
          if (event.slug === e) {
            selectedBusinessList.splice(index, 1);
            selectedBusinessList.unshift(event);
            selectedBusinessType.push(event.id);
            items.push(event.slug);
          }
        });
      });
      setValue((prevState) => ({
        ...prevState,
        businessType: items,
      }));

      // Business Name
      setValue((prevState) => ({
        ...prevState,
        businessName: suggest.gmaps.name,
      }));

      // Address
      setValue((prevState) => ({
        ...prevState,
        address: suggest.gmaps.formatted_address,
      }));

      // Country,City,State Data
      const components = suggest.gmaps.address_components;

      let country_name = "";
      let state_name = "";
      let country_id = "";
      let state_id = "";
      let city_name = "";

      for (let key of components) {
        if (key.types[0] === "administrative_area_level_1") {
          setStateName(key.long_name);
          state_name = key.long_name;
        }

        if (key.types[0] === "country") {
          setCountryName(key.short_name);
          country_name = key.short_name;
        }

        if (key.types[0] === "locality" || key.types[0] === "sublocality_level_1") {
          setCityName(key.long_name);
          city_name = key.long_name;
        }
      }

      if (country_name) {
        if (country_name === "US") {
          let indexCountry = countryList.findIndex((obj) => obj.name === "USA");
          let indexCountryID = countryList[indexCountry].id;

          country_id = indexCountryID;
          setCountryId(indexCountryID);

          setValue((prevState) => ({
            ...prevState,
            country_id: indexCountryID,
          }));
        } else {
          let indexCountry = countryList.findIndex(
            (obj) => obj.name === country_name
          );
          let indexCountryID = countryList[indexCountry].id;

          country_id = indexCountryID;
          setCountryId(indexCountryID);

          setValue((prevState) => ({
            ...prevState,
            country_id: indexCountryID,
          }));
        }
      }

      if (state_name && country_id) {
        let result = getMethod(
          url.getCountryList + "?country_id=" + country_id
        );

        result.then(function (res) {
          if (res && res.data) {
            setStateList(res?.data?.[0]?.data?.state);
            setStateDataList(res?.data?.[0]?.data?.state)

            let indexState = res?.data?.[0]?.data?.state?.findIndex(
              (obj) => obj.name === state_name
            );
            let indexStateID = res?.data?.[0]?.data?.state?.[indexState]?.id;

            state_id = indexStateID;
            setStateId(indexStateID);

            setValue((prevState) => ({
              ...prevState,
              state_id: indexStateID,
            }));

            if (city_name && country_id && state_id) {
              let result = getMethod(
                url.getCountryList +
                "?country_id=" +
                country_id +
                "&state_id=" +
                state_id
              );

              result.then(function (res) {
                if (res && res.data) {
                  setCityList(res?.data?.[0]?.data?.cities);
                  setCityDataList(res?.data?.[0]?.data?.cities);

                  let indexCity = res?.data?.[0]?.data?.cities.findIndex(
                    (obj) => obj.name === city_name
                  );
                  let indexCityID = res?.data?.[0]?.data?.cities?.[indexCity]?.id;

                  setValue((prevState) => ({
                    ...prevState,
                    city_id: indexCityID,
                  }));
                }
              });
            }
          }
        });
      }

      if (suggest.gmaps.international_phone_number) {
        setMobileNumber(suggest.gmaps.international_phone_number);
        if (suggest.gmaps.international_phone_number) {
          const phoneDetails = parsePhoneNumber(
            suggest?.gmaps?.international_phone_number
          );
          if (phoneDetails !== undefined) {
            setMobileCountryCode(phoneDetails?.countryCallingCode);
            setValue((prevState) => ({
              ...prevState,
              countryCode: `+${phoneDetails?.countryCallingCode}`,
            }));

            setValue((prevState) => ({
              ...prevState,
              phoneContactNumber: phoneDetails?.nationalNumber,
            }));
          }
        }
      }
      // Website Link
      if (suggest.gmaps.website) {
        setValue((prevState) => ({
          ...prevState,
          website_link: suggest?.gmaps?.website,
        }));
      }
      // Lat Long set
      setValue((prevState) => ({
        ...prevState,
        latitude: suggest?.location?.lat,
      }));

      setValue((prevState) => ({
        ...prevState,
        longitude: suggest?.location?.lng,
      }));

      // Business Hours
      const busHours =
        suggest?.gmaps?.opening_hours && suggest?.gmaps?.opening_hours?.periods;
      const weekdaysHours =
        suggest?.gmaps?.opening_hours && suggest?.gmaps?.opening_hours?.weekday_text;

      if (busHours && weekdaysHours) {
        let indexKey = 0;
        const list = [...businessHours];

        busHours.forEach((data, index) => {
          list[data.open.day]["startTime"] =
            (data.open.hours.toString().length === 1
              ? "0" + data.open.hours
              : data.open.hours) +
            ":" +
            (data.open.minutes.toString().length === 1
              ? "0" + data.open.minutes
              : data.open.minutes) +
            ":00";
          list[data.open.day]["endTime"] =
            ((data.close !== undefined &&
              data.close.hours.toString().length === 1 ? "0" + data.close.hours
              :
              data.close ? data.close.hours : "00")) +
            ":" +
            ((data.close !== undefined &&
              data.close.minutes.toString().length === 1
              ? "0" + data.close.minutes
              : data.close
                ? data.close.minutes
                : "00")) +
            ":00";
          list[data.open.day]["is_holiday"] = false;
        });

        setBusinessHours(list);
      }

      // Photos
      const busPhotos = suggest.gmaps.photos;
      if (suggest) {
        const allImages = [];
        const allImagesIds = [];

        busPhotos &&
          busPhotos.length > 0 &&
          busPhotos.forEach((data, index) => {
            let photoUrl = data.getUrl();

            const formData = new FormData();
            formData.append("filePath", photoUrl);
            formData.append("fileType", "U");

            const result = postMethod(url.imageUpload, formData);
            result.then((res) => {
              if (res) {
                allImages[index] = res.data;
                allImagesIds[index] = {
                  imageId: res.data.id,
                  orderNumber: index + 1,
                };
              }
            });
          });

        setValue((prevState) => ({
          ...prevState,
          image_ids: allImagesIds,
        }));

        setBusinessImages(allImages);
        setBusinessImagesData(allImages);
        setBusinessImagesIds(allImagesIds);

        handlePutSubmit(
          suggest.placeId,
          allImagesIds,
          items,
          suggest && suggest.gmaps.name,
          suggest && suggest.gmaps.formatted_address,
          suggest.gmaps.international_phone_number !== undefined
            ? parsePhoneNumber(suggest.gmaps.international_phone_number)
              .nationalNumber
            : "",
          suggest.gmaps.international_phone_number !== undefined
            ? parsePhoneNumber(suggest.gmaps.international_phone_number)
              .countryCallingCode
            : "",
          suggest && suggest.gmaps.website,
          businessHours,
          country_id,
          suggest.location.lat,
          suggest.location.lng
        );
      }
    }
  };

  const onSuggestSelect2 = (suggest) => {
    setValue((prevState) => ({
      ...prevState,
      address: "",
      latitude: "",
      longitude: "",
      country_id: "",
      state_id: "",
      city_id: "",
    }));

    if (suggest) {
      // Address
      console.log(suggest);
      setValue((prevState) => ({
        ...prevState,
        address: suggest.gmaps.formatted_address,
      }));

      // Country,City,State Data
      const components = suggest.gmaps.address_components;

      let country_name = "";
      let state_name = "";
      let country_id = "";
      let state_id = "";
      let city_name = "";

      for (let key of components) {
        if (key.types[0] === "administrative_area_level_1") {
          setStateName(key.long_name);
          state_name = key.long_name;
        }

        if (key.types[0] === "country") {
          setCountryName(key.short_name);
          country_name = key.short_name;
        }

        if (key.types[0] === "locality") {
          setCityName(key.long_name);
          city_name = key.long_name;
        }
      }

      if (country_name) {
        if (country_name === "US") {
          let indexCountry = countryList.findIndex((obj) => obj.name === "USA");
          let indexCountryID = countryList[indexCountry].id;

          country_id = indexCountryID;
          setCountryId(indexCountryID);

          setValue((prevState) => ({
            ...prevState,
            country_id: indexCountryID,
          }));
        } else {
          let indexCountry = countryList.findIndex(
            (obj) => obj.name === country_name
          );
          let indexCountryID = countryList[indexCountry].id;

          country_id = indexCountryID;
          setCountryId(indexCountryID);

          setValue((prevState) => ({
            ...prevState,
            country_id: indexCountryID,
          }));
        }
      }

      if (state_name && country_id) {
        let result = getMethod(
          url.getCountryList + "?country_id=" + country_id
        );

        result.then(function (res) {
          if (res && res.data) {
            setStateList(res?.data?.[0]?.data?.state);
            setStateDataList(res?.data?.[0]?.data?.state)

            let indexState = res.data[0].data.state.findIndex(
              (obj) => obj.name === state_name
            );
            let indexStateID = res?.data?.[0]?.data?.state?.[indexState]?.id;

            state_id = indexStateID;
            setStateId(indexStateID);

            setValue((prevState) => ({
              ...prevState,
              state_id: indexStateID,
            }));

            if (city_name && country_id && state_id) {
              let result = getMethod(
                url.getCountryList +
                "?country_id=" +
                country_id +
                "&state_id=" +
                state_id
              );

              result.then(function (res) {
                if (res && res.data) {
                  setCityList(res.data[0].data.cities);
                  setCityDataList(res.data[0].data.cities);

                  let indexCity = res.data[0].data.cities.findIndex(
                    (obj) => obj.name === city_name
                  );
                  let indexCityID = res.data[0].data.cities[indexCity].id;

                  setValue((prevState) => ({
                    ...prevState,
                    city_id: indexCityID,
                  }));
                }
              });
            }
          }
        });
      }
      // Lat Long set
      setValue((prevState) => ({
        ...prevState,
        latitude: suggest.location.lat,
      }));

      setValue((prevState) => ({
        ...prevState,
        longitude: suggest.location.lng,
      }));
    }
  };

  const changePhoneNumber = (e) => {
    setMobileNumber(e);

    if (e) {
      const phoneDetails = parsePhoneNumber(e);

      if (phoneDetails !== undefined) {
        setMobileCountryCode(`+${phoneDetails.countryCallingCode}`);

        setValue((prevState) => ({
          ...prevState,
          countryCode: `+${phoneDetails.countryCallingCode}`,
          phoneContactNumber: phoneDetails.nationalNumber,
        }));
      }
    }
  };

  const handleSelectRemovePhone = (event) => {
    if (event.target.value === mobileCountryCode) {
      setValue((prevState) => ({
        ...prevState,
        phoneContactNumber: "",
      }));
    }
  }

  const changeSmsNumber = (e) => {
    setSmsMobileNumber(e);
    if (e) {
      const phoneDetails = parsePhoneNumber(e);
      if (phoneDetails !== undefined) {
        setMobileCountryCode(`+${phoneDetails.countryCallingCode}`);
        setValue((prevState) => ({
          ...prevState,
          countryCode: `+${phoneDetails.countryCallingCode}`,
          SMScontactNumber: phoneDetails.nationalNumber,
        }));
      }
    }
  };

  const handleInputChanged = (e) => {
    setValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectRemove = (event) => {
    if (event.target.value === "") {
      setValue((prevState) => ({
        ...prevState,
        [event.target.name]: "",
      }));
    }
  }

  const handleInputChangedDescription = (e) => {
    setValue((prevState) => ({
      ...prevState,
      ["description"]: e.target.value,
    }));
  };

  const handleSelectRemoveDescription = (event) => {
    if (event.target.value === "") {
      setValue((prevState) => ({
        ...prevState,
        ["description"]: "",
      }));
    }
  }

  const handleLocationChanged = (event) => {
    const { name, value } = event.target;

    setValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "country_id") {
      setCountryId(value);
      getStateListData(value);
      setValue((prevState) => ({
        ...prevState,
        ["city_id"]: "",
      }));

      setValue((prevState) => ({
        ...prevState,
        ["state_id"]: "",
      }));
    }

    if (name === "state_id") {
      getCityListData(countryId ? countryId : 1, value);
      setStateId(value);
      setValue((prevState) => ({
        ...prevState,
        ["city_id"]: "",
      }));
    }
  };

  const selectedItem = (id, index, field, data) => {
    let datas = "";
    let slug_data = " ";
    if (field === "business") {
      datas = [...selectedBusinessType];
      slug_data = [...selectedBusinessTypeSlug];
    } else if (field === "cuisin") {
      datas = [...selectedCuisine];
      slug_data = [...selectedCuisineSlug];
    } else if (field === "atmosphere") {
      datas = [...selectedAtmosphere];
      slug_data = [...selectedAtmosphereSlug];
    } else if (field === "facility") {
      datas = [...selectedFacility];
      slug_data = [...selectedFacilitySlug];
    } else if (field === "goodfor") {
      datas = [...selectedGoodFor];
      slug_data = [...selectedGoodForSlug];
    } else if (field === "parking") {
      datas = [...selectedParking];
      slug_data = [...selectedParkingSlug];
    }


    if (datas.includes(data.id)) {
      let index = datas.indexOf(data.id);
      datas.splice(index, 1);
      let index1 = slug_data.indexOf(data.slug);
      slug_data.splice(index1, 1);
    } else {
      datas.push(data.id);
      slug_data.push(data.slug);
    }

    if (field === "business") {
      setSelectedBusinessType(datas);
      setSelectedBusinessTypeSlug(slug_data);
      setValue((prevState) => ({
        ...prevState,
        businessType: slug_data,
      }));

      const slist = [];
      const uslist = [];

      selectedBusinessList.forEach((value, index) => {
        if (datas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedBusinessList(slist.concat(uslist.sort((a, b) => a.id - b.id)));
    } else if (field === "cuisin") {
      setSelectedCuisine(datas);
      setSelectedCuisineSlug(slug_data);
      setValue((prevState) => ({
        ...prevState,
        cuisine: slug_data,
      }));
      const slist = [];
      const uslist = [];
      selectedCuisineList.forEach((value, index) => {
        if (datas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedCuisineList(slist.concat(uslist));
    } else if (field === "atmosphere") {
      if (datas.length <= 3) {
        setSelectedAtmosphere(datas);
        setSelectedAtmosphereSlug(slug_data);
        setValue((prevState) => ({
          ...prevState,
          atmosphere: slug_data,
        }));

        const slist = [];
        const uslist = [];
        selectedAtmosphereList.forEach((value, index) => {
          if (datas.includes(value.id)) {
            slist.unshift(value);
          } else {
            uslist.push(value);
          }
        });
        setSelectedAtmosphereList(slist.concat(uslist));
      } else {
        toast.error("you can select any three ");
      }
    } else if (field === "facility") {
      setSelectedFacility(datas);
      setSelectedFacilitySlug(slug_data);
      setValue((prevState) => ({
        ...prevState,
        facility: slug_data,
      }));

      const slist = [];
      const uslist = [];
      selectedFacilitiesList.forEach((value, index) => {
        if (datas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedFacilitiesList(slist.concat(uslist));
    } else if (field === "goodfor") {
      setSelectedGoodForSlug(slug_data);
      setSelectedGoodFor(datas);
      setValue((prevState) => ({
        ...prevState,
        goodFor: slug_data,
      }));

      const slist = [];
      const uslist = [];
      selectedGoodForList.forEach((value, index) => {
        if (datas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedGoodForList(slist.concat(uslist));
    } else if (field === "parking") {
      setSelectedParkingSlug(slug_data);
      setSelectedParking(datas);
      setValue((prevState) => ({
        ...prevState,
        parking: slug_data,
      }));

      const slist = [];
      const uslist = [];
      selectedParkingList.forEach((value, index) => {
        if (datas.includes(value.id)) {
          slist.unshift(value);
        } else {
          uslist.push(value);
        }
      });
      setSelectedParkingList(slist.concat(uslist));
    }
  };

  const viewSelect = (e) => {
    switch (e.target.id) {
      case "1":
        setView(value.businessType);
        setModalType("Business");
        setSelectedCount(selectedBusinessType.length);
        break;
      case "2":
        setView(value.cuisine);
        setModalType("Cuisine");
        setSelectedCount(selectedCuisine.length);
        break;
      case "3":
        setView(value.atmosphere);
        setModalType("Atmosphere");
        setSelectedCount(selectedAtmosphere.length);
        break;
      case "4":
        setView(value.facility);
        setModalType("Facility");
        setSelectedCount(selectedFacility.length);
        break;
      case "5":
        setView(value.goodFor);
        setModalType("GoodFor");
        setSelectedCount(selectedGoodFor.length);
        break;
      case "6":
        setView(value.parking);
        setModalType("Parking");
        setSelectedCount(selectedParking.length);
        break;
    }
  };

  const handleChangeTime = (event, index) => {
    const list = [...businessHours];
    list[index][event.target.name] = event.target.value;
    setBusinessHours(list);
  };

  const handleChangeTimeHoliday = (event, index) => {
    const list = [...businessHours];
    list[index][event.target.name] = event.target.checked;
    setBusinessHours(list);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function (e) {
          if (this.width < 350 || this.height < 300) {
            toast.error("File size must be equal or greater than 350*300");
          } else {
            imageUpload(file);
          }
        };
      };

      reader.readAsDataURL(file);
      return file;
    });
  });

  const onDropRejected = useCallback((Files) => {
    toast.error("Please upload valid file png or jpg");
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept: "image/jpeg, image/jpg, image/png",
  });

  const imageUpload = async (file) => {
    let images = [...businessImages];
    let imageList = [...businessImagesData];
    let imageListIds = [...businessImagesIds];

    const formData = new FormData();
    formData.append("filePath", file);
    formData.append("fileType", "I");

    const results = await postMethod(url.imageUpload, formData);

    results.data.forEach((value, index) => {
      imageListIds.push({
        imageId: value.id,
        orderNumber: imageListIds.length + 1,
      });
      images.push({
        file: value.filePath,
        id: value.id,
        orderNumber: images.length + 1,
      });
      imageList.push({
        file: value.filePath,
        id: value.id,
        orderNumber: imageList.length + 1,
      });
    });

    setBusinessImages(images);
    setBusinessImagesData(imageList);
    setBusinessImagesIds(imageListIds);

    setValue((prevState) => ({
      ...prevState,
      image_ids: imageListIds,
    }));
  };

  const imageDelete = (data, index) => {
    let images = [...businessImages];
    let imageListIds = [...businessImagesIds];
    const imageData = [];
    const imageDataWithfile = [];

    images.splice(index, 1);
    imageListIds.splice(index, 1);

    images.forEach((value, index) => {
      imageData.push({ imageId: value.id, orderNumber: index + 1 });
      imageDataWithfile.push({
        file: value.file,
        id: value.id,
        orderNumber: index + 1,
      });
    });

    setBusinessImagesData(imageDataWithfile);
    setBusinessImagesIds(imageData);
    setBusinessImages(imageDataWithfile);

    setValue((prevState) => ({
      ...prevState,
      image_ids: imageData,
    }));
  };

  const handleSelection = () => {
    createUpdateBusiness();
  };

  const handlePutSubmit = async (
    placeId,
    image,
    selectedBusinessType,
    businessName,
    address,
    phoneContactNumber,
    countryCode,
    website,
    businessHours,
    country_id,
    latitude,
    longitude
  ) => {
    delete value.state_id;
    delete value.city_id;
    if (reservationLink !== "") {
      value["reservation_link"] = reservationLink;
    }

    value["placeId"] = placeId;
    value["image_ids"] = image;
    value["businessType"] = selectedBusinessType;
    value["businessName"] = businessName;
    value["address"] = address;
    value["phoneContactNumber"] = phoneContactNumber;
    value["countryCode"] = `+${countryCode}`;
    value["website_link"] = website;
    value["opening_closing_holiday"] = businessHours;
    value["country_id"] = country_id;
    value["latitude"] = latitude;
    value["longitude"] = longitude;

    setTimeout(async () => {
      let result = await postMethod(url.postBusiness, value);
      setCreateBusinessId(result.data.business_id);
    }, 2500);
  };

  const createUpdateBusiness = async () => {
    value["opening_closing_holiday"] = businessHours;
    if (businessOpendate !== "") {
      value["businessOpen_date"] = businessOpendate;
    }

    let result = "";
    const onClick = () => {
      const id = toast.success(result.data.message, () => { });
    };

    const onUpdateClick = () => {
      const id = toast.success(result.data.response, () => { });
    };

    const onErrorClick = () => {
      const id = toast.error("Please fill all mandatory field");
      setError(true);
    };
    value["image_ids"] = businessImagesIds;
    /*if (reservationLink !== "") {
      
    }*/
    value["reservation_link"] = reservationLink;
    value["ribbon_text"] = ribbonText;
    value["isRecommended"] = isRecommend;
    /*if (isRecommend !== "") {
      
    }*/

    // if (params.id !== "add") {
    result = await putMethod(url.putBusiness + createBusinessId + "/", value);
    if (result.status === 200 || result.status === 201) {
      onUpdateClick(result.data.response);
      navigate(-1);
    } else if (result.status === 400) {
      if (result.data.error.address && result.data.error.address[0]) {
        toast.error("Address Field may not be blank");
      } else if (result.data.error.city_id && result.data.error.city_id[0]) {
        toast.error("City Name may not be blank");
      } else if (
        result.data.error.country_id &&
        result.data.error.country_id[0]
      ) {
        toast.error("Country Name may not be blank");
      } else if (
        result.data.error.countryCode &&
        result.data.error.countryCode[0]
      ) {
        toast.error("countryCode may not be blank");
      } else if (
        result.data.error.description &&
        result.data.error.description[0]
      ) {
        toast.error("Description may not be blank");
      } else if (
        result.data.error.atmosphere &&
        result.data.error.atmosphere[0]
      ) {
        toast.error("Atmosphere Field may not be blank");
      } else if (
        result.data.error.averagePricePerPax &&
        result.data.error.averagePricePerPax[0]
      ) {
        toast.error("Average Price may not be blank");
      } else if (
        result.data.error.businessName &&
        result.data.error.businessName[0]
      ) {
        toast.error("Business Name not be blank");
      } else if (
        result.data.error.businessType &&
        result.data.error.businessType[0]
      ) {
        toast.error("Business Type may not be blank");
      } else if (result.data.error.cuisine && result.data.error.cuisine[0]) {
        toast.error("Cuisine Field may not be blank");
      } else if (result.data.error.facility && result.data.error.facility[0]) {
        toast.error("Facility Field not be blank");
      } else if (result.data.error.goodFor && result.data.error.goodFor[0]) {
        toast.error("GoodFor Field may not be blank");
      } else if (result.data.error.parking && result.data.error.parking[0]) {
        toast.error("Parking Field may not be blank");
      } else if (result.data.error.state_id && result.data.error.state_id[0]) {
        toast.error("State Name not be blank");
      } else if (
        result.data.error.businessOpen_date &&
        result.data.error.businessOpen_date[0]
      ) {
        toast.error("Business Open Date not be blank");
      }
    } else {
      onErrorClick();
    }
  };

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = stateDataList.filter((user) => {
        return user.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setStateList(results);
    } else {
      setStateList(stateDataList);
    }
  };

  const filters = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = cityDataList.filter((user) => {
        return user.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setCityList(results);
    } else {
      setCityList(cityDataList);
    }
  };

  function strCapitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    let images = [...businessImages];
    let imageListIds = [...businessImagesIds];
    const imageData = [];
    const imageDataWithfile = [];

    const dragItemImageListIdsContent = imageListIds[dragItem.current];
    const dragItemImageContent = images[dragItem.current];

    imageListIds.splice(dragItem.current, 1);
    imageListIds.splice(dragOverItem.current, 0, dragItemImageListIdsContent);

    images.splice(dragItem.current, 1);
    images.splice(dragOverItem.current, 0, dragItemImageContent);

    images.forEach((value, index) => {
      imageData.push({ imageId: value.id, orderNumber: index + 1 });
      imageDataWithfile.push({
        file: value.file,
        id: value.id,
        orderNumber: index + 1,
      });
    });

    dragItem.current = null;
    dragOverItem.current = null;

    setBusinessImagesData(imageDataWithfile);
    setBusinessImagesIds(imageData);
    setBusinessImages(imageDataWithfile);

    setValue((prevState) => ({
      ...prevState,
      image_ids: imageData,
    }));
  };

  //dropdown

  const getReservationType = async () => {
    const result = await getMethod(url.dropdown);
    setDropdown(result.data);
  };

  const colors = dropdown.map((v) => v);

  const ReservationTypeChange = (e) => {
    setValue({ ...value, ["reservation_types"]: e.slug });
    setReservation_name(e.name);
  };

  const changeValue = () => {
    setError(true);
    setStateList(stateDataList);
  };

  return (
    <div className="createBusiness">
      <div className="bodyOfCreateBusiness">
        <div className="left_side text-center">
          <img src={companyLogo} alt="Berb" width="180" />
        </div>
        <div className="right_side"></div>
      </div>
      <div className="just">
        <div className="content-area">
          <div className="d-flex ">
            <div className="back_btn pe-4" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000" Name="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{ margin: "0px" }} >
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
              </svg>
            </div>
            <Row className="heading" style={{ width: "100%" }}>
              <p>Tell us about your Business</p>
            </Row>
          </div>
          <Row className="container">
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="food_type_side" >
              <Row className="food_type_row">
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <p>Type of Business</p>
                    {selectedBusinessType.length > 0 && (
                      <p className="isoSelectedText ms-2">
                        {selectedBusinessType.length}
                      </p>
                    )}
                  </div>
                  <span data-bs-toggle="modal" data-bs-target="#exampleModal2" id="1" onClick={(e) => viewSelect(e)}>VIEW ALL</span>
                </div>
                <div className=" d-flex justify-content-start flex-wrap shimmer_btn">
                  {loading
                    ? shimArr.map(() => {
                      return (
                        <div style={{ paddingRight: "12px" }}>
                          <ShimmerThumbnail width={94} height={70} rounded />
                        </div>
                      );
                    })
                    : selectedBusinessList &&
                    selectedBusinessList.map((data, index) => {
                      return (
                        index <= 3 && (
                          <div className={`food_type`} onClick={() => selectedItem(data.id, index, "business", data)}>
                            <div className={`box ${selectedBusinessType.includes(data.id) && "selectStiker"}`}>
                              <div className="selectedbox"></div>
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        )
                      );
                    })}
                </div>
              </Row>
              <Row className="food_type_row">
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <p>Select the Cuisine </p>
                    {selectedCuisine.length > 0 && (
                      <p className="isoSelectedText ms-2"> {selectedCuisine.length} </p>
                    )}
                  </div>
                  <span data-bs-toggle="modal" data-bs-target="#exampleModal2" id="2" onClick={(e) => viewSelect(e)}>VIEW ALL</span>
                </div>
                <div className=" d-flex justify-content-start flex-wrap">
                  {loading
                    ? shimArr.map(() => {
                      return (
                        <div style={{ paddingRight: "12px" }}>
                          <ShimmerThumbnail width={94} height={70} rounded />
                        </div>
                      );
                    })
                    : selectedCuisineList &&
                    selectedCuisineList.map((data, index) => {
                      return (
                        index <= 3 && (
                          <div className={`food_type`} onClick={() => selectedItem(data.id, index, "cuisin", data)}>
                            <div className={`box ${selectedCuisine.includes(data.id) && "selectStiker"}`} >
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        )
                      );
                    })}
                </div>
              </Row>
              <Row className="food_type_row">
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <p>Select the Atmosphere (Any 3)</p>
                    {selectedAtmosphere.length > 0 && (
                      <p className="isoSelectedText ms-2">{selectedAtmosphere.length}</p>
                    )}
                  </div>
                  <span data-bs-toggle="modal" data-bs-target="#exampleModal2" id="3" onClick={(e) => viewSelect(e)}> VIEW ALL</span>
                </div>
                <div className=" d-flex justify-content-start flex-wrap">
                  {loading
                    ? shimArr.map(() => {
                      return (
                        <div style={{ paddingRight: "12px" }}>
                          <ShimmerThumbnail width={94} height={70} rounded />
                        </div>
                      );
                    })
                    : selectedAtmosphereList &&
                    selectedAtmosphereList.map((data, index) => {
                      return (
                        index <= 3 && (
                          <div className={`food_type`} onClick={() => selectedItem(data.id, index, "atmosphere", data)}>
                            <div className={`box ${selectedAtmosphere.includes(data.id) && "selectStiker"}`}>
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        )
                      );
                    })}
                </div>
              </Row>
              <Row className="food_type_row">
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <p>Facilities</p>
                    {selectedFacility.length > 0 && (
                      <p className="isoSelectedText ms-2">{selectedFacility.length}</p>
                    )}
                  </div>
                  <span data-bs-toggle="modal" data-bs-target="#exampleModal2" id="4" onClick={(e) => viewSelect(e)} >VIEW ALL</span>
                </div>
                <div className=" d-flex justify-content-start flex-wrap">
                  {loading
                    ? shimArr.map(() => {
                      return (
                        <div style={{ paddingRight: "12px" }}>
                          <ShimmerThumbnail width={94} height={70} rounded />
                        </div>
                      );
                    })
                    : selectedFacilitiesList &&
                    selectedFacilitiesList.map((data, index) => {
                      return (
                        index <= 3 && (
                          <div className={`food_type`} onClick={() => selectedItem(data.id, index, "facility", data)}>
                            <div className={`box ${selectedFacility.includes(data.id) && "selectStiker"}`} >
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        )
                      );
                    })}
                </div>
              </Row>
              <Row className="food_type_row">
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <p>Good For</p>
                    {selectedGoodFor.length > 0 && (
                      <p className="isoSelectedText ms-2">{selectedGoodFor.length}</p>
                    )}
                  </div>
                  <span data-bs-toggle="modal" data-bs-target="#exampleModal2" id="5" onClick={(e) => viewSelect(e)}>VIEW ALL</span>
                </div>
                <div className=" d-flex justify-content-start flex-wrap">
                  {loading
                    ? shimArr.map(() => {
                      return (
                        <div style={{ paddingRight: "12px" }}>
                          <ShimmerThumbnail width={94} height={70} rounded />
                        </div>
                      );
                    })
                    : selectedGoodForList &&
                    selectedGoodForList.map((data, index) => {
                      return (
                        index <= 3 && (
                          <div className={`food_type`} onClick={() => selectedItem(data.id, index, "goodfor", data)}>
                            <div className={`box ${selectedGoodFor.includes(data.id) && "selectStiker"}`} >
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        )
                      );
                    })}
                </div>
              </Row>
              <Row className="food_type_row">
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <p>Parking</p>
                    {selectedParking.length > 0 && (
                      <p className="isoSelectedText ms-2">{selectedParking.length}</p>
                    )}
                  </div>
                  <span data-bs-toggle="modal" data-bs-target="#exampleModal2" id="6" onClick={(e) => viewSelect(e)} >VIEW ALL</span>
                </div>
                <div className=" d-flex justify-content-start flex-wrap">
                  {loading
                    ? shimArr.map(() => {
                      return (
                        <div style={{ paddingRight: "12px" }}>
                          <ShimmerThumbnail width={94} height={70} rounded />
                        </div>
                      );
                    })
                    : selectedParkingList &&
                    selectedParkingList.map((data, index) => {
                      return (
                        index <= 3 && (
                          <div className={`food_type`} onClick={() => selectedItem(data.id, index, "parking", data)}>
                            <div className={`box ${selectedParking.includes(data.id) && "selectStiker"}`}>
                              <img src={data.icon ? data.icon : foodicon} alt="" object />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        )
                      );
                    })}
                </div>
              </Row>
              <div className="table_content">
                <Row className="table_content_header">
                  <Col xs={2} className="text-center">Day</Col>
                  <Col xs={4} className="text-center">Opening</Col>
                  <Col xs={4} className="text-center">Closing</Col>
                  <Col xs={2} >Closed</Col>
                </Row>
                <div className="table_content_body">
                  {businessHours &&
                    businessHours.map((data, index) => {
                      return (
                        <Row className="table_content_data">
                          <Col xs={2} className="table_content_day text-center">{strCapitalize(data.days.substring(0, 3))}</Col>
                          <Col xs={4} className="bg-colored text-center TimePicker">
                            <span>
                              <FormControl
                                sx={{ m: 1, minWidth: "auto" }}
                                className="input_picker flex-row"
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <Stack spacing={3}>
                                    <input
                                      name="startTime"
                                      className="inputTime"
                                      type="time"
                                      step="2"
                                      placeholder="00:00:00"
                                      defaultValue={data.startTime}
                                      value={data.startTime}
                                      onChange={(e) =>
                                        handleChangeTime(e, index)
                                      }
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </FormControl>
                            </span>
                          </Col>
                          <Col xs={4} className="bg-colored text-center TimePicker">
                            <span>
                              <FormControl
                                sx={{ m: 1, minWidth: "auto" }}
                                className="input_picker flex-row "
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <Stack spacing={3}>
                                    <input
                                      name="endTime"
                                      className="inputTime"
                                      type="time"
                                      step="2"
                                      id="appt"
                                      placeholder="00:00:00"
                                      defaultValue={data.endTime}
                                      value={data.endTime}
                                      onChange={(e) =>
                                        handleChangeTime(e, index)
                                      }
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </FormControl>
                            </span>
                          </Col>
                          <Col xs={2} className="text-center checkb">
                            <input type="checkbox" name="is_holiday" defaultChecked={data.is_holiday} checked={data.is_holiday} onChange={(e) => handleChangeTimeHoliday(e, index)} />
                          </Col>
                        </Row>
                      );
                    })}
                </div>
              </div>

              <Row className="left_form mt-4">
                <p className="dropzone_heading mb-3 ">Average Price Per Pax</p>
                <div className="input-group  ">
                  <input type="number" placeholder="Average Price Per Pax" name="averagePricePerPax" className="form-control" defaultValue={value.averagePricePerPax} onChange={(e) => handleInputChanged(e)} onSelect={(e) => handleSelectRemove(e)} />
                </div>
              </Row>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6} onSuggestSelect2 xxl={6} className="left-data">
              <form action="">
                <Row className="left_form">
                  <div className="p-0">
                    {isLoaded && (
                      <Geosuggest
                        onChange={(e) => handleBusinesChanged(e)}
                        onUpdateSuggests={onUpdateSuggests}
                        onSuggestSelect={onSuggestSelect}
                        initialValue={value.businessName}
                        placeholder={"Search Business / Name of Business"}
                        onSuggestSelect2
                        types={["establishment"]}
                        country={["US"]}
                      />
                    )}
                  </div>
                </Row>
                {value.businessName === "" && error && (
                  <p style={{ color: "red" }}>This field may not be blank</p>
                )}

                <Row className="left_form">
                  <div className="p-0 ">
                    {isLoaded && (
                      <Geosuggest
                        name="address"
                        onChange={(e) => handleAddressChanged(e)}
                        initialValue={value.address}
                        onUpdateSuggests={onUpdateSuggests}
                        onSuggestSelect={onSuggestSelect2}
                        placeholder={"Address"}
                        country={["US"]}
                      />
                    )}
                  </div>
                </Row>
                {value.address === "" && error && (
                  <p style={{ color: "red" }}>This field may not be blank</p>
                )}

                <Row className="left_form">
                  <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown mt-2"  >
                    <InputLabel id="Country">Country</InputLabel>
                    <Select
                      labelId="Country"
                      placeholder="Country"
                      id="Select"
                      name="country_id"
                      className="form-control"
                      value={value.country_id}
                      defaultValue={value.country_id}
                      onChange={(e) => handleLocationChanged(e)}
                    >
                      {countryList.map((key, idx) => (
                        <MenuItem key={key.id} value={key.id}>
                          {key.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {value.country_id === "" && error && (
                      <p style={{ color: "red" }}> This field may not be blank </p>
                    )}
                  </FormControl>
                </Row>
                <Row className="left_form">
                  <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                    <InputLabel id="search-select-label">State</InputLabel>
                    <Select
                      MenuProps={{ autoFocus: false }}
                      labelId="search-select-label"
                      id="search-select"
                      name="state_id"
                      className="form-control"
                      value={value.state_id}
                      label="Options"
                      onChange={(e) => handleLocationChanged(e)}
                      onClose={() => setStateList(stateDataList)}
                    >
                      <ListSubheader>
                        <TextField
                          size="small"
                          autoFocus
                          placeholder="Type to search..."
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={filter}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>

                      {stateList &&
                        stateList.length > 0 &&
                        stateList.map((key) => (
                          <MenuItem key={key.id} value={key.id}>
                            {key.name}
                          </MenuItem>
                        ))}
                    </Select>

                    {value.state_id === "" && error && (
                      <p style={{ color: "red" }}> This field may not be blank</p>
                    )}
                  </FormControl>
                </Row>

                <Row className="left_form">
                  <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown" >
                    <InputLabel id="search-select-label">City</InputLabel>
                    <Select
                      MenuProps={{ autoFocus: false }}
                      labelId="search-select-label"
                      id="search-select"
                      name="city_id"
                      className="form-control"
                      value={value.city_id}
                      label="Options"
                      onChange={(e) => handleLocationChanged(e)}
                      onClose={() => setCityList(cityDataList)}
                    >
                      <ListSubheader>
                        <TextField
                          size="small"
                          autoFocus
                          placeholder="Type to search..."
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={filters}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>

                      {cityList &&
                        cityList.length > 0 &&
                        cityList.map((key) => (
                          <MenuItem key={key.id} value={key.id}>
                            {key.name}
                          </MenuItem>
                        ))}
                    </Select>

                    {value.city_id === "" && error && (
                      <p style={{ color: "red" }}>This field may not be blank</p>
                    )}
                  </FormControl>
                </Row>
                {/* <Row className='left_form'>
                                    <div className="input-group p-0 ">
                                        <input required type="text" placeholder='Latitude' className="form-control" aria-label="Latitude" name="latitude" defaultValue={value.latitude} onChange={(e) => handleInputChanged(e)} />
                                    </div>
                                </Row>
                                <Row className='left_form'>
                                    <div className="input-group p-0 ">
                                        <input required type="text" placeholder='Longitude' className="form-control" aria-label="Longitude" name="longitude" defaultValue={value.longitude} onChange={(e) => handleInputChanged(e)} />
                                    </div>
                                </Row> */}
                <Row className="left_form phone_number">
                  <p className="dropzone_heading mb-2 d-block p-0">Phone no. line 1</p>
                  <Col xs={9} className="p-0">
                    <div className="input-group p-0 ">
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        name="phoneContactNumber"
                        defaultCountry="US"
                        value={value.countryCode + value.phoneContactNumber}
                        onChange={(e) => changePhoneNumber(e)}
                        onSelect={(e) => handleSelectRemovePhone(e)}
                        MaxLength={15}
                      />
                    </div>
                  </Col>

                  <Col className="d-flex align-items-center validate ps-4">
                    <p className="m-0">Validate</p>
                  </Col>
                </Row>
                {/* {value.phoneContactNumber === "" && error && (
                  <p style={{ color: "red" }}>This field may not be blank</p>
                )} */}
                <Row className="left_form phone_number">
                  <p className="dropzone_heading mb-2 d-block p-0">Phone no. line 2</p>
                  <Col xs={9} className="p-0">
                    <div className="input-group p-0 ">
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        name="SMScontactNumber"
                        defaultCountry="US"
                        value={value.countryCode + value.SMScontactNumber}
                        onChange={(e) => changeSmsNumber(e)}
                        MaxLength={15}
                      />
                    </div>
                  </Col>
                  <Col className="d-flex align-items-center validate ps-4">
                    <p className="m-0">Validate</p>
                  </Col>
                </Row>

                <Row className="input_area">
                  <Col className="p-0">
                    <p className="dropzone_heading ">Upload image</p>
                    <div className="container p-0">
                      <div className="totalImg text-center" data-bs-toggle="modal" data-bs-target="#exampleModal">{businessImagesData.length}</div>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        {isDragAccept && <p>All files will be accepted</p>}
                        {isDragReject && <p>Some files will be rejected</p>}
                        {!isDragActive && (
                          <div className="text-center">
                            <img src={uploadicon} alt="" />
                            <Row>
                              <p className="m-0">Drag and Drop or <div>browse</div></p>
                            </Row>
                            <Row>
                              <span>Only png, jpg is supported</span>
                              <span>Minimum Image Resolution Should be 350*300</span>
                            </Row>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="left_form pt-3">
                  <div className="input-group p-0 ">
                    <input type="text" placeholder="Menu Image Link" className="form-control" name="menu_url" aria-label="Amount (to the nearest dollar)" defaultValue={value.menu_url} onChange={(e) => handleInputChanged(e)} onSelect={(e) => handleSelectRemove(e)} />
                  </div>
                </Row>
                <Row className="left_form">
                  <div className="input-group p-0 ">
                    <input type="text" placeholder="Reservation Link" className="form-control" onChange={(e) => setReservationLink(e.target.value)} value={reservationLink} />
                  </div>
                </Row>
                <Row className="dropdownList">
                  <DropdownList data={colors} textField="name" value={reservation_name} onSelect={(e) => ReservationTypeChange(e)} placeholder="Reservation Type" />
                </Row>
                <Row className="input_area input_textarea">
                  <textarea id="" cols="30" rows="7" placeholder="Description about your resturant" className="description" name="description" defaultValue={value.description} onChange={(e) => handleInputChangedDescription(e)} onSelect={(e) => handleSelectRemoveDescription(e)} ></textarea>
                </Row>
                <Row className="left_form">
                  <div className="input-group p-0 ">
                    <input type="text" placeholder="Website link (Optional)" className="form-control" name="website_link" aria-label="Amount (to the nearest dollar)" defaultValue={value.website_link} onChange={(e) => handleInputChanged(e)} onSelect={(e) => handleSelectRemove(e)} />
                  </div>
                </Row>
                <Row className="left_form">
                  <div className="input-group p-0 ">
                    <input type="text" placeholder="Instagram link (Optional)" className="form-control" name="instagram_link" aria-label="Amount (to the nearest dollar)" defaultValue={value.instagram_link} onChange={(e) => handleInputChanged(e)} onSelect={(e) => handleSelectRemove(e)} />
                  </div>
                </Row>
                <Row className="left_form">
                  <div className="input-group p-0 ">
                    <input type="text" placeholder="Facebook link (Optional)" className="form-control" name="facebook_link" aria-label="Amount (to the nearest dollar)" defaultValue={value.facebook_link} onChange={(e) => handleInputChanged(e)} onSelect={(e) => handleSelectRemove(e)} />
                  </div>
                </Row>
                <Row className="left_form">
                  <div className={params.id !== "add" ? "input-group p-0 " : "input-group p-0"}>
                    <input type="text" placeholder="Youtube link (Optional)" className="form-control" name="youtube_link" aria-label="Amount (to the nearest dollar)" defaultValue={value.youtube_link} onChange={(e) => handleInputChanged(e)} onSelect={(e) => handleSelectRemove(e)} />
                  </div>
                </Row>

                <Row className="left_form">
                  <div className="input-group p-0 ">
                    <input type="text" placeholder="Ribbon text" className="form-control" onChange={(e) => setRibbonText(e.target.value)} value={ribbonText} />
                  </div>
                </Row>

                <Row className="left_form">
                  <div className="input-group p-0 business-open-date">
                    <FormControl sx={{ m: 1, minWidth: "auto " }} className="flex-row" >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <MobileDatePicker
                            inputFormat="yyyy-MM-dd"
                            value={moment(businessOpendate).format("MM-DD-YYYY")}
                            onChange={handleChangeBusinessOpenDate}
                            renderInput={(params) => (
                              <TextField
                                placeholder="Business Open Date"
                                {...params}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </Row>

                <Row>
                  <Col xs={3} className=" checkb d-flex check-box-text">
                    <input type="checkbox" checked={isRecommend} onChange={(e) => setIsRecommend(!isRecommend)} />
                    <p style={{ margin: "15px" }}>Recommend</p>
                  </Col>
                </Row>

                <Row>
                  <Button className="commom_btn " onClick={() => handleSelection()}>Save</Button>
                </Row>
              </form>
            </Col>
          </Row>
        </div>
      </div>
      {/* modal----------======== */}
      <div class="modal " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div class="modal-body">
              <Row className="input_area">
                <Col className="d-flex manage_flex">
                  <div className="container p-0">
                    <div {...getRootProps({ className: "dropzone d-inline" })}>
                      <input {...getInputProps()} />
                      {isDragAccept && <p>All files will be accepted</p>}
                      {isDragReject && <p>Some files will be rejected</p>}
                      {!isDragActive && (
                        <i class="bi bi-plus align-self-center w-100 " data-bs-toggle="modal" data-bs-target="#exampleModal" ></i>
                      )}
                    </div>
                  </div>
                  {businessImagesData &&
                    businessImagesData.map((data, index) => {
                      return (
                        <div className="upload " onDragStart={(e) => dragStart(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={drop} key={index} draggable>
                          <div data-key={index} className="crossBtn" onClick={() => imageDelete(data, index)}>
                            <i class="bi bi-x"></i>
                          </div>
                          <img src={data.filePath ? data.filePath : data.file ? data.file : ""} alt="nothing" />
                        </div>
                      );
                    })}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      {/* modal_2------------ */}
      <div className="modal2">
        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
              </div>
              <div class="modal-body">
                <div className="d-flex align-items-baseline">
                  <h5 class="modal-title me-2" id="exampleModalLabel">Select the {modalType}</h5>
                  {modalType === "Business" &&
                    selectedBusinessType.length > 0 && (
                      <p className="isoSelectedTexts ">{selectedBusinessType.length}</p>
                    )}
                  {modalType === "Cuisine" && selectedCuisine.length > 0 && (
                    <p className="isoSelectedTexts ">{selectedCuisine.length}</p>
                  )}
                  {modalType === "Atmosphere" &&
                    selectedAtmosphere.length > 0 && (
                      <p className="isoSelectedTexts ">{selectedAtmosphere.length}</p>
                    )}
                  {modalType === "Facility" && selectedFacility.length > 0 && (
                    <p className="isoSelectedTexts ">{selectedFacility.length}</p>
                  )}
                  {modalType === "GoodFor" && selectedGoodFor.length > 0 && (
                    <p className="isoSelectedTexts "> {selectedGoodFor.length} </p>
                  )}
                  {modalType === "Parking" && selectedParking.length > 0 && (
                    <p className="isoSelectedTexts ">{selectedParking.length}</p>
                  )}
                </div>
                <Row className="input_area">
                  <Col className="d-flex manage_flex ">
                    {modalType === "Business" &&
                      selectedBusinessList &&
                      selectedBusinessList.map((data, index) => {
                        return (
                          <div className={`food_type `} onClick={() => selectedItem(data.id, index, "business", data)} >
                            <div className={`box ${selectedBusinessType.includes(data.id) && "selectStiker"}`}>
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        );
                      })}
                    {modalType === "Cuisine" &&
                      selectedCuisineList &&
                      selectedCuisineList.map((data, index) => {
                        return (
                          <div className={`food_type `} onClick={() => selectedItem(data.id, index, "cuisin", data)}>
                            <div className={`box ${selectedCuisine.includes(data.id) && "selectStiker"}`}>
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        );
                      })}
                    {modalType === "Atmosphere" &&
                      selectedAtmosphereList &&
                      selectedAtmosphereList.map((data, index) => {
                        return (
                          <div className={`food_type `} onClick={() => selectedItem(data.id, index, "atmosphere", data)}>
                            <div className={`box ${selectedAtmosphere.includes(data.id) && "selectStiker"}`}  >
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        );
                      })}
                    {modalType === "Facility" &&
                      selectedFacilitiesList &&
                      selectedFacilitiesList.map((data, index) => {
                        return (
                          <div className={`food_type`} onClick={() => selectedItem(data.id, index, "facility", data)} >
                            <div className={`box ${selectedFacility.includes(data.id) && "selectStiker"}`}>
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        );
                      })}
                    {modalType === "GoodFor" &&
                      selectedGoodForList &&
                      selectedGoodForList.map((data, index) => {
                        return (
                          <div className={`food_type`} onClick={() => selectedItem(data.id, index, "goodfor", data)} >
                            <div className={`box ${selectedGoodFor.includes(data.id) && "selectStiker"}`} >
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        );
                      })}
                    {modalType === "Parking" &&
                      selectedParkingList &&
                      selectedParkingList.map((data, index) => {
                        return (
                          <div className={`food_type `} onClick={() => selectedItem(data.id, index, "parking", data)} >
                            <div className={`box ${selectedParking.includes(data.id) && "selectStiker"}`}>
                              <img src={data.icon ? data.icon : foodicon} alt="" />
                            </div>
                            <p className="text-center">{data.name}</p>
                          </div>
                        );
                      })}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
