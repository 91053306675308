import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./PushNotification.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { deleteMethod, getMethod, postMethod, postMethodwithoutBody } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import ToastService from "react-material-toast";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "../../screens/loader/Loader";
import NotificationIcon from "../../assets/images/PushNotification/push-notification.png";
import { Checkbox, FormControl, TextField, Select, MenuItem, InputLabel, IconButton, Box } from "@mui/material";
import { ListSubheader, InputAdornment, Stack } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToMarkdown from 'draftjs-to-markdown';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider, MobileTimePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { height } from "@mui/system";
import InputEmoji from "react-input-emoji";

let timeOutId;

const toast = ToastService.new({
    place: "topRight",
    duration: 2,
    maxCount: 4,
});

export default function PushNotification() {
    const navigate = useNavigate();
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateDataList, setStateDataList] = useState([]);
    const [cityDataList, setCityDataList] = useState([]);
    const [pushSelectList, setPushSelectList] = useState([]);
    const [pushListData, setPushListData] = useState([]);
    const [userSelectList, setUserSelectList] = useState([]);
    const [userSelectListData, setUserSelectListData] = useState([]);
    const [bussinessListData, setBussinessListData] = useState([]);
    const [bussinessSelectList, setBussinessSelectList] = useState([]);
    const [searchInput, setSearchInput] = useState("")
    const [userLsit, setUserList] = useState([])



    const [formValue, setFormValue] = useState({
        title: "",
        state_id: [],
        city_id: [],
        device_type: [],
        time: null,
        date: null,
        user_type: [],
        push_type: [],
        select_push_data: "",
        select_user: [],
        login_type: [],
        select_business: "",
    })

    useEffect(() => {
        getStateListData(1);
        getSelectPushList();
    }, [])

    const getStateListData = async (country_id) => {
        const result = await getMethod(
            url.getCountryList + "?country_id=" + country_id
        );
        if (result.status === 401) {
            toast.error(result.data.detail);
            navigate("/");
        }
        setStateList(result.data[0].data.state);
        setStateDataList(result.data[0].data.state);
    };

    const getCityListData = async (country_id, state_id) => {
        const result = await getMethod(
            url.getCountryList + "?country_id=" + country_id + "&state_id=" + state_id
        );
        setCityList(result.data[0].data.cities);
        setCityDataList(result.data[0].data.cities);
    };

    const handleLocationChanged = (event) => {
        const { name, value } = event.target;
        setFormValue((prevState) => ({
            ...prevState,
            [name]: [value],
        }));
        if (name === "state_id") {
            getCityListData(1, value);
            setFormValue((prevState) => ({
                ...prevState,
                ["city_id"]: "",
            }));
        }
    };

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== "") {
            const results = stateDataList.filter((user) => {
                return user.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setStateList(results);
        } else {
            setStateList(stateDataList);
        }
    };

    const filters = (e) => {
        const keyword = e.target.value;
        if (keyword !== "") {
            const results = cityDataList.filter((user) => {
                return user.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCityList(results);
        } else {
            setCityList(cityDataList);
        }
    };

    const handleChangeTime = (event) => {
        let time_new = moment(event).format("YYYY-MM-DD hh:mm a ");
        setFormValue({
            ...formValue,
            ["time"]: time_new
        })
    }

    const handleChangeDate = (event) => {
        let date_new = moment(event).format("YYYY-MM-DD");
        setFormValue({
            ...formValue,
            ["date"]: date_new
        })
    };

    const handleChange = (e) => {
        setFormValue((pre) => ({
            ...pre,
            ["title"]: e.target.value
        }))
    }

    const handleDropdownChange = (event) => {
        if (event.target.value === "events" || event.target.value === "deals") {
            formValue.select_business = "";
            formValue.select_push_data = "";
        }
        if (event.target.value === "link-based-promotion" || event.target.value === "association-based-promotion") {
            formValue.select_push_data = "";
        }
        setFormValue({
            ...formValue,
            [event.target.name]: [event.target.value]
        });
        getCampaignList(event.target.value, event.target.name);
    }
    const handleDropdownValueChange = (event) => {
        if (event.target.value === "events" || event.target.value === "deals") {
            formValue.select_business = "";
            formValue.select_push_data = "";
        }
        if (event.target.value === "link-based-promotion" || event.target.value === "association-based-promotion") {
            formValue.select_push_data = "";
        }
        setFormValue({
            ...formValue,
            ["select_push_data"]: event.target.value,
        });
        getCampaignList(event.target.value, event.target.name);
    }

    const handleDropdownTypePushChange = (event) => {
        setFormValue({
            ...formValue,
            ["push_type"]: event.target.value,
        });
        getCampaignList(event.target.value, event.target.name);
    }

    const getCampaignList = async (value, name) => {
        if (value === "link-based-promotion" || value === "association-based-promotion") {
            setPushSelectList([]);
            setPushListData([]);
            const result = await getMethod(url.getCampaignBasedPushList + value);
            setPushSelectList(result.data.results.data);
            setPushListData(result.data.results.data);
        } else if (value === "business-details") {
            setPushSelectList([]);
            setPushListData([]);
            const result = await getMethod(url.getBussinessPushList);
            setPushSelectList(result.data.results);
            setPushListData(result.data.results);
        } else if (value === "events" || value === "deals") {
            setPushSelectList([]);
            setPushListData([]);
            setBussinessSelectList([]);
            setBussinessListData([]);
            const result = await getMethod(url.getSelectBussinessList);
            setBussinessSelectList(result.data.results.data);
            setBussinessListData(result.data.results.data);
        } else if (name === "select_business" && value) {
            if (formValue.push_type === "events") {
                setPushSelectList([]);
                setPushListData([]);
                const result = await getMethod(url.postEvent_DealBussiness + "post-event" + "&businessId=" + value);
                setPushSelectList(result.data.results.data);
                setPushListData(result.data.results.data);

            } else if (formValue.push_type === "deals") {
                setPushSelectList([]);
                setPushListData([]);
                const result = await getMethod(url.postEvent_DealBussiness + "post-special" + "&businessId=" + value);
                setPushSelectList(result.data.results.data);
                setPushListData(result.data.results.data);
            }
        }
    }

    const getSelectPushList = async () => {
        const result = await getMethod(url.getSelectUserPushList);
        if (result.status === 401) {
            toast.error(result.data.detail);
            navigate("/");
        }
        setUserSelectList(result.data.results);
        setUserSelectListData(result.data.results);
    }

    const searchUser = async (text) => {
        const result = await getMethod(url.getSelectUserPushList + text.toLowerCase())
        if (result.status === 200 || result.status === 201) {
            setUserList(result.data.results)
        }

    }


    function debounce(func, timeout = 1000) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    function debounceUser(func, delay) {
        return (...args) => {
            if (timeOutId) clearTimeout(timeOutId);
            timeOutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    }

    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value)
        debounceCall(e.target.value)
    }

    const debounceCall = debounceUser(searchUser, 300)

    const handleSearchBusiness = debounce((value) => searchBusinessList(value));

    const searchBusinessList = async (value) => {
        if (formValue.push_type === "business-details") {
            if (value !== "") {
                const result = await getMethod(url.getBussinessPushList + value.toLowerCase());
                setPushSelectList(result?.data?.results);
            } else {
                setPushSelectList(pushListData);
            }
        } else if (formValue.push_type === "events" || formValue.push_type === "deals") {
            if (value !== "") {
                const result = await getMethod(url.getBussinessPushList + value.toLowerCase());
                setBussinessSelectList(result?.data?.results);
            } else {
                setBussinessSelectList(bussinessListData);
            }
        } else {
            if (value !== "") {
                const results = pushListData.filter((user) => {
                    return user.name.toLowerCase().startsWith(value.toLowerCase());
                });
                setPushSelectList(results);
            } else {
                setPushSelectList(pushListData);
            }
        }
    }



    const SortSelectUser = (e) => {
        const keyword = e.target.value;
        if (keyword !== "") {
            const results = userSelectListData.filter((user) => {
                return user.first_name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setUserSelectList(results);
        } else {
            setUserSelectList(userSelectListData);
        }
    }

    const handleSubmit = async () => {

        let body = {
            "title": formValue.title,
            "body": formValue.body,
            "device_type": formValue.device_type,
            "users_role": formValue.user_type,
            "type_of_push": formValue.push_type,
            "push_data": formValue.select_push_data,
            "users_list": formValue.select_user,
            "login_type": formValue.login_type,
            "state_id": formValue.state_id,
            "city_id": formValue.city_id,
        }

        const result = await postMethod(url.postPushNotification, body);
        if (result.status === 200 || result.status === 201) {
            toast.success(result.data.success);
            navigate("/businesses")
        } else if (result.status === 400) {
            toast.error(result.data.error);
        }

    }

    const onEditorStateChange = (e) => {
        let val = draftToMarkdown(convertToRaw(e.getCurrentContent()));
        setFormValue({
            ...formValue,
            ["body"]: val
        })
    };

    function handleOnEnter(text) {
        console.log(text)
        setFormValue((pre) => ({
            ...pre,
            ["body"]: text
        }))
    }

    return (

        <div className="notification" style={{ padding: "20px 20px 20px 20px" }}>

            <div style={{ display: "flex", alignItems: "center" }}>
                <div className="back_btn" onClick={() => navigate(-1)} style={{ cursor: "pointer", paddingLeft: "0px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000" Name="bi bi-arrow-left-short" viewBox="0 0 16 16" >
                        <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                    </svg>
                </div>
                <img src={NotificationIcon} />
                <p className="heading">Push Notifications</p>
            </div>

            <Row className="post_card" style={{ padding: "30px 20px 20px 20px" }}>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <Row style={{ marginBottom: "20px" }}>
                        <Col className="align-self-center" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <input type="text" placeholder="Enter Notification Title" className="NotificationTitle" value={formValue.title} onChange={(e) => handleChange(e)} />
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: "20px" }}>
                        <Col className="" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                <InputLabel id="search-select-label">Type of User</InputLabel>
                                <Select
                                    labelId="search-select-label"
                                    id="search-select"
                                    name="user_type"
                                    className="form-control"
                                    value={formValue.user_type}
                                    onChange={(e) => handleDropdownChange(e)}
                                >
                                    <MenuItem key={"app-user"} value={"app-user"}>App User</MenuItem>
                                    <MenuItem key={"business-user"} value={"business-user"}>Business User</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>


                    {/* <Row style={{ margin: "0px", padding: "0px", marginBottom: "20px" }} >
                        <Col className="align-self-center" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ padding: "0px 10px 0px 0px" }}>
                            <FormControl className="custom_dropdown">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3} className="NotificationTitles">
                                        <MobileTimePicker
                                            inputFormat="hh:mm a"
                                            value={formValue.time}
                                            onChange={handleChangeTime}
                                            renderInput={(params) => (
                                                <TextField placeholder="-:--" {...params} size="small" />
                                            )}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            <AccessTimeIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </FormControl>
                        </Col>

                        <Col className="align-self-center" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ padding: "0px 0px 0px 10px" }}>
                            <FormControl className="custom_dropdown">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3} className="NotificationTitles">
                                        <MobileDatePicker
                                            inputFormat="dd/mm/yy"
                                            value={moment(formValue.date).format('DD/MM/YYYY')}
                                            onChange={handleChangeDate}
                                            renderInput={(params) => (
                                                <TextField placeholder="dd/mm/yy" {...params} size="small" />
                                            )}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end">
                                                            <EventIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                    </Row> */}

                </Col>

                <Col className="" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} >
                    <Row style={{ marginBottom: "20px" }}>
                        <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                <InputLabel id="search-select-label"> Select State</InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="search-select-label"
                                    id="search-select"
                                    name="state_id"
                                    className="form-control"
                                    value={formValue.state_id}
                                    label="Options"
                                    onChange={(e) => handleLocationChanged(e)}
                                    onClose={() => setStateList(stateDataList)}

                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={filter}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {stateList && stateList.length > 0 && stateList.map((key) => (
                                        <MenuItem key={key.id} value={key.id}>
                                            {key.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Col>

                        <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                <InputLabel id="search-select-label">Select City</InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="search-select-label"
                                    id="search-select"
                                    name="city_id"
                                    className="form-control"
                                    value={formValue.city_id}
                                    label="Options"
                                    onChange={(e) => handleLocationChanged(e)}
                                    onClose={() => setCityList(cityDataList)}
                                >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={filters}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {cityList && cityList.length > 0 && cityList.map((key) => (
                                        <MenuItem key={key.id} value={key.id}>
                                            {key.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>

                        <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                <InputLabel id="search-select-label">Device Type</InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="search-select-label"
                                    id="search-select"
                                    name="device_type"
                                    className="form-control"
                                    value={formValue.device_type}
                                    label="Options"
                                    onChange={(e) => handleDropdownChange(e)}
                                >
                                    <MenuItem key={"andriod"} value={"andriod"}>Android</MenuItem>
                                    <MenuItem key={"ios"} value={"ios"}>Ios</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>

                    </Row>

                    <Row style={{ marginBottom: "20px" }}>
                        <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                <InputLabel id="search-select-label">Type of Push</InputLabel>
                                <Select
                                    MenuProps={{ autoFocus: false }}
                                    labelId="search-select-label"
                                    id="search-select"
                                    name="push_type"
                                    className="form-control"
                                    value={formValue.push_type}
                                    onChange={(e) => handleDropdownTypePushChange(e)}
                                >
                                    <MenuItem key={"link-based-promotion"} value={"link-based-promotion"}>Link Based Campaign</MenuItem>
                                    <MenuItem key={"association-based-promotion"} value={"association-based-promotion"}>Association Based Campaign</MenuItem>
                                    <MenuItem key={"business-details"} value={"business-details"}>Restaurant</MenuItem>
                                    <MenuItem key={"normal"} value={"normal"}>Normal</MenuItem>
                                    <MenuItem key={"events"} value={"events"}>Event</MenuItem>
                                    <MenuItem key={"deals"} value={"deals"}>Deal</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>

                        {formValue.push_type === "events" || formValue.push_type === "deals" ?
                            <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                    <InputLabel id="search-select-label">Select Business</InputLabel>
                                    <Select
                                        MenuProps={{ autoFocus: false }}
                                        labelId="search-select-label"
                                        id="search-select"
                                        name="select_business"
                                        className="form-control"
                                        value={formValue.select_business}
                                        label="Options"
                                        onChange={(e) => handleDropdownChange(e)}
                                    >
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                autoFocus
                                                placeholder="Type to search..."
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e) => handleSearchBusiness(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {bussinessSelectList && bussinessSelectList.length > 0 ? bussinessSelectList.map((key) => (
                                            <MenuItem key={key.id} value={key.id}>
                                                {key.name || key.businessName}
                                            </MenuItem>
                                        ))
                                            :
                                            <p style={{ paddingLeft: "20px" }}>No data found</p>
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                            :
                            formValue.push_type !== "normal" &&
                            <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                    <InputLabel id="search-select-label">Select Push Data</InputLabel>
                                    <Select
                                        MenuProps={{ autoFocus: false }}
                                        labelId="search-select-label"
                                        id="search-select"
                                        name="select_push_data"
                                        className="form-control"
                                        value={formValue.select_push_data}
                                        label="Options"
                                        onChange={(e) => handleDropdownValueChange(e)}
                                    >
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                autoFocus
                                                placeholder="Type to search..."
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e) => handleSearchBusiness(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {pushSelectList && pushSelectList.length > 0 ? pushSelectList.map((key) => (
                                            <MenuItem key={key.post_id || key.id} value={key.post_id || key.id}>
                                                {key.name || key.post_Name || key.businessName}
                                            </MenuItem>
                                        ))
                                            :
                                            <p style={{ paddingLeft: "20px" }}>No data found</p>
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                        }

                        {(formValue.push_type === "events" || formValue.push_type === "deals") ?
                            <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                    <InputLabel id="search-select-label">Select Push Data</InputLabel>
                                    <Select
                                        MenuProps={{ autoFocus: false }}
                                        labelId="search-select-label"
                                        id="search-select"
                                        name="select_push_data"
                                        className="form-control"
                                        value={formValue.select_push_data}
                                        label="Options"
                                        onChange={(e) => handleDropdownValueChange(e)}
                                    >
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                autoFocus
                                                placeholder="Type to search..."
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e) => handleSearchBusiness(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {pushSelectList && pushSelectList.length > 0 ? pushSelectList.map((key) => (
                                            <MenuItem key={key.post_id || key.id} value={key.post_id || key.id}>
                                                {key.name || key.post_Name || key.businessName}
                                            </MenuItem>
                                        ))
                                            :
                                            <p style={{ paddingLeft: "20px" }}>No data found</p>
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                            :
                            <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                    <InputLabel id="search-select-label">Select User</InputLabel>
                                    <Select
                                        MenuProps={{ autoFocus: false }}
                                        labelId="search-select-label"
                                        id="search-select"
                                        name="select_user"
                                        className="form-control"
                                        value={formValue.select_user}
                                        label="Options"
                                        onChange={(e) => handleDropdownChange(e)}
                                    >
                                        <ListSubheader>
                                            <TextField
                                                value={searchInput}
                                                size="small"
                                                autoFocus
                                                placeholder="Type to search User"
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(event) => handleSearchInputChange(event)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {userLsit && userLsit.length > 0 ? userLsit.map((key) => (
                                            <MenuItem key={key.id} value={key.id}>
                                                {key.first_name} {key.last_name}
                                            </MenuItem>
                                        ))
                                            :
                                            <p style={{ paddingLeft: "20px" }}>No data found</p>
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                        }

                        {/* {formValue.push_type === "normal" &&
                            <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                    <InputLabel id="search-select-label">Login Type</InputLabel>
                                    <Select
                                        MenuProps={{ autoFocus: false }}
                                        labelId="search-select-label"
                                        id="search-select"
                                        name="login_type"
                                        className="form-control"
                                        value={formValue.login_type}
                                        label="Options"
                                        onChange={(e) => handleDropdownChange(e)}
                                    >
                                        <MenuItem key={"ios"} value={"ios"}>Ios</MenuItem>
                                        <MenuItem key={"facebook"} value={"facebook"}>Facebook</MenuItem>
                                        <MenuItem key={"google"} value={"google"}>Google</MenuItem>
                                        <MenuItem key={"apple"} value={"apple"}>Apple</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        } */}

                    </Row>

                    <Row style={{ marginBottom: "20px" }}>
                        {(formValue.push_type === "events" || formValue.push_type === "deals") &&
                            <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                    <InputLabel id="search-select-label">Select User</InputLabel>
                                    <Select
                                        MenuProps={{ autoFocus: false }}
                                        labelId="search-select-label"
                                        id="search-select"
                                        name="select_user"
                                        className="form-control"
                                        value={formValue.select_user}
                                        label="Options"
                                        onChange={(e) => handleDropdownChange(e)}
                                    >
                                        <ListSubheader>
                                            <TextField
                                                value={searchInput}
                                                size="small"
                                                autoFocus
                                                placeholder="Type to search User"
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(event) => handleSearchInputChange(event)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        {userLsit && userLsit.length > 0 ? userLsit.map((key) => (
                                            <MenuItem key={key.id} value={key.id}>
                                                {key.first_name} {key.last_name}
                                            </MenuItem>
                                        ))
                                            :
                                            <p style={{ paddingLeft: "20px" }}>No data found</p>
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                        }
                        {/* {formValue.push_type !== "normal" &&
                            <Col className="" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                                    <InputLabel id="search-select-label">Login Type</InputLabel>
                                    <Select
                                        MenuProps={{ autoFocus: false }}
                                        labelId="search-select-label"
                                        id="search-select"
                                        name="login_type"
                                        className="form-control"
                                        value={formValue.login_type}
                                        label="Options"
                                        onChange={(e) => handleDropdownChange(e)}
                                    >
                                        <MenuItem key={"ios"} value={"ios"}>Ios</MenuItem>
                                        <MenuItem key={"facebook"} value={"facebook"}>Facebook</MenuItem>
                                        <MenuItem key={"google"} value={"google"}>Google</MenuItem>
                                        <MenuItem key={"apple"} value={"apple"}>Apple</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        } */}
                    </Row>

                </Col>

                {/* <div className='textEditor'>
                    <div className="editor_container">
                        <Editor
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                inline: {
                                    inDropdown: false,
                                    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                                },
                                blockType: {
                                    inDropdown: true,
                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                },
                                fontSize: {
                                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                },
                                fontFamily: {
                                    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'MrEavesXLSanNarOT-Reg', 'MrEavesXLSanNarOT-Bold', 'MrEavesXLSanNarOT-Bold', 'MrEavesXLSanOT-Reg', 'MrEavesXLSanOT-Bold'],
                                },
                                list: {
                                    inDropdown: false,
                                    options: ['unordered', 'ordered', 'indent', 'outdent'],
                                },
                                textAlign: {
                                    inDropdown: false,
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                colorPicker: {
                                    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                },
                                link: {
                                    inDropdown: false,
                                    showOpenOptionOnHover: true,
                                    defaultTargetOption: '_self',
                                    options: ['link', 'unlink'],
                                },
                                emoji: {
                                    emojis: [
                                        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                        '✅', '❎', '💯',
                                    ],
                                },
                                embedded: {
                                    defaultSize: {
                                        height: 'auto',
                                        width: 'auto',
                                    },
                                },
                                image: {
                                    urlEnabled: true,
                                    uploadEnabled: true,
                                    alignmentEnabled: true,
                                    previewImage: false,
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                    alt: { present: false, mandatory: false },
                                    defaultSize: {
                                        height: 'auto',
                                        width: 'auto',
                                    },
                                },
                                history: {
                                    inDropdown: false,
                                    options: ['undo', 'redo'],
                                },
                            }}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={
                                { options: ['emoji'] }
                            }
                        />
                    </div>
                </div> */}

                <InputEmoji value={formValue.body} onChange={(e) => handleOnEnter(e)} />
                <div className="text-end d-block mt-3">
                    <Button className="button" onClick={() => handleSubmit()}>Send</Button>
                </div>
            </Row>

        </div>
    );
}
